import React, { useState, useEffect } from 'react';
import { Pressable, View, FlatList, Image, ImageBackground, Text, StyleSheet, TouchableOpacity, ImageSourcePropType, Animated } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation, useFocusEffect } from '@react-navigation/native';

import { MaterialIcons } from '@expo/vector-icons'; // import icon library
import { ChatType } from '../types';
import { newChat, fetchChats } from '../utils';
import { persoImages, PersoImage } from '../utils';


const ChatListItem = ({ chat, chats, setChats }: { chat: ChatType, chats: any, setChats: any }) => {
    const navigation = useNavigation();
    const [data, setData] = useState({});

    const handleChatPress = () => {
        navigation.navigate('Chat', { chatName: chat.id });
    };

    const handleArchiveChat = () => {
        const updatedChats = [...chats];
        const chatIndex = updatedChats.findIndex((c) => c.id === chat.id);
        updatedChats[chatIndex].isArchived = !updatedChats[chatIndex].isArchived;
        setChats(updatedChats);
    };
    // animation
    const [fadeAnim] = useState(new Animated.Value(0));
    useEffect(() => {
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: false
        }).start();
    }, []);

    useEffect(() => {
        const _fetchChatData = async () => {
            try {
                const dataJson = await AsyncStorage.getItem(`${chat.id}_data`);
                if (chats) {
                    const _data = JSON.parse(dataJson);
                    setData(_data);
                    console.log(_data)
                }
            } catch (error) {
                console.error('Error fetching chats from storage:', error);
            }
        };
        _fetchChatData();
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            const _fetchChatData = async () => {
                try {
                    const dataJson = await AsyncStorage.getItem(`${chat.id}_data`) as string;
                    if (chats) {
                        const _data = JSON.parse(dataJson);
                        setData(_data);
                    }
                } catch (error) {
                    console.error('Error fetching chats from storage:', error);
                }
            };
            _fetchChatData();
            // Force re-render when navigating back to ChatList
            //setChats([...chats]);
        }, [navigation])
    );

    let showCharacter = false
    let chatName = chat.name
    if (data) {
        const searchedPersoImage: PersoImage | undefined = persoImages.find(persoImage => persoImage.name === data.character);
        if (searchedPersoImage) {
            showCharacter = true;
            chatName = `${searchedPersoImage.name}-${chatName.split('-')[1]}`
        }

    }
    return (
        <TouchableOpacity style={styles.chatItem} onPress={handleChatPress}>
            <Animated.View
                style={{
                    ...styles.chatItemInner,
                    opacity: fadeAnim,
                }}
            >
                <Image source={chat.icon} style={styles.chatIcon} />
                <View style={styles.chatContent}>
                    <Text style={styles.chatName}>{chatName}</Text>
                    {data && data.lastMessage && (
                        <Text style={styles.chatLastMessage}>
                            <Text style={styles.chatTime}>{data.lastMessage.time}</Text>{" "}
                            {data.lastMessage.sender}: {data.lastMessage.text.substring(0, 60) + '...'}
                        </Text>
                    )}
                </View>
                <TouchableOpacity
                    style={styles.archiveButton}
                >
                    <MaterialIcons
                        name="delete" // <-- use the delete icon
                        size={24}
                        color="red"
                        onPress={handleArchiveChat} />

                </TouchableOpacity>
            </Animated.View>
        </TouchableOpacity >
    );
};

export default function ChatList() {
    const [chats, setChats] = useState<Chat[]>([]);

    const loadChatMessages = async (chats) => {
        try {
            await Promise.all(chats.map(async (chat, i) => {
                const storedChatMsgs = await AsyncStorage.getItem(chat.id);
                const storedChatData = await AsyncStorage.getItem(`${chat.id}_data`);
                if (storedChatMsgs) {
                    let messages = JSON.parse(storedChatMsgs);
                    chats[i].messages = messages;
                }
                if (storedChatData) {
                    let data = JSON.parse(storedChatData);
                    chats[i].data = data;
                }
            }));
            return chats;
        } catch (error) {
            console.error(error);
            // handle the error appropriately, e.g. throw a custom error or return a default value
        }
    };
    const navigation = useNavigation();
    const addChat = async () => {
        try {
            const chat = newChat();
            const chatsLoaded = await loadChatMessages([chat, ...chats]);

            setChats(chatsLoaded);


            navigation.navigate('Chat', { chatName: chat.id });
        } catch (error) {
            console.log('Une erreur est survenue :', error);
        }
    }

    useEffect(() => {
        const _fetchChats = async () => {
            try {
                const chats = await fetchChats();
                if (chats) {
                    const storedChatsData = JSON.parse(chats);
                    setChats(storedChatsData);
                }
            } catch (error) {
                console.error('Error fetching chats from storage:', error);
            }
        };
        _fetchChats();
    }, []);

    useEffect(() => {
        const storeChats = async (chats: ChatType[]) => {
            try {
                console.log("adding a chat")
                await AsyncStorage.setItem('chats', JSON.stringify(chats));
            } catch (error) {
                console.error('Error storing chats:', error);
            }
        };
        storeChats(chats);
    }, [chats]);

    return (
        <View style={styles.container}>
            {chats.filter(chat => !chat.isArchived).length === 0 &&
                <View style={styles.initContainer}>
                    <ImageBackground
                        source={require('../assets/images/askord.jpg')}
                        style={styles.initImage}
                        imageStyle={styles.initImageBackground}
                    >
                        <View style={styles.initOverlay} />
                        <Text style={styles.initText}>
                            Chat with Askord's AI personalities and explore a world of creativity and knowledge at your fingertips!
                        </Text>
                    </ImageBackground>
                    <TouchableOpacity style={styles.initAddButton} onPress={addChat}>
                        <Text style={styles.initAddButtonText}>START</Text>
                    </TouchableOpacity>
                </View>
            }
            <FlatList
                data={chats.filter(chat => !chat.isArchived)}
                keyExtractor={(chat) => chat.name}
                renderItem={({ item }) => <ChatListItem chat={item} chats={chats} setChats={setChats} />}
            />
            {chats.filter(chat => !chat.isArchived).length !== 0 && (
                <TouchableOpacity style={styles.addButton} onPress={addChat}>
                    <Text style={styles.addButtonText}>+</Text>
                </TouchableOpacity>
            )
            }
        </View>
    );
};

const styles = StyleSheet.create({
    initOverlay: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: 'rgba(0,0,0,0.0)',
    },
    initImage: {
        width: '100%',
        height: '100%',
        resizeMode: 'cover',
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    initImageBackground: {
        borderRadius: 300,
    },

    initContainer: {
        padding: '5%',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#000',
    },
    initText: {
        fontSize: 16,
        textAlign: 'center',
        color: '#FFF',
        zIndex: 1,
        paddingHorizontal: 30,
        paddingTop: '45%',
    },
    initAddButton: {
        position: 'absolute',
        width: 64,
        backgroundColor: '#000',
        borderRadius: 32,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    initAddButtonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#fff',
        lineHeight: 64,
    },
    archiveButton: {
        backgroundColor: 'lightgray',
        borderRadius: 8,
        padding: 8,
        margin: 8,
        alignItems: 'center',
    },
    archiveButtonText: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    chatItem: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 16,
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
    },
    chatItemInner: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    chatIcon: {
        width: 60,
        height: 60,
        borderRadius: 30,
        borderWidth: 2,
        borderColor: '#000',
    },
    chatContent: {
        flex: 1,
        marginLeft: 16,
    },
    chatName: {
        fontSize: 18,
        fontWeight: 'bold',
        textTransform: 'capitalize',
    },
    chatLastMessage: {
        color: '#888',
        marginTop: 4,
    },
    chatTime: {
        color: '#888',
        marginLeft: 16,
    },
    addButton: {
        position: 'absolute',
        bottom: 24,
        right: 24,
        width: 64,
        height: 64,
        backgroundColor: '#000',
        borderRadius: 32,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    addButtonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#fff',
        lineHeight: 64,
    }
});