import React, { useRef, useState, useEffect } from 'react';
import { View, ScrollView, TextInput, TouchableOpacity, StyleSheet, Text, Image, Animated, AccessibilityInfo } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { KeyboardAwareFlatList } from 'react-native-keyboard-aware-scroll-view';
import RNPickerSelect from 'react-native-picker-select';
import { getLocales } from 'expo-localization';
import { Platform } from 'react-native';
import Anim from '../assets/anim.json';
import fire from '../assets/fire2.json';
import sound from '../assets/sound.json';
import Imagination from './Imagination';
import * as Speech from 'expo-speech';
import { languages, persoImages } from '../utils';

//const server = 'http://192.168.0.24:5000/chat';
const server = 'https://chatgenius.1motion.ch/chat';

export default function Chat({ route }: any) {
    const { chatName } = route.params;
    const [showPersos, setShowPersos] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [character, setCharacter] = useState("");
    const [disableSendButton, setDisableSendButton] = useState(true);
    const [messages, setMessages] = useState([] as any);
    const [messagesRaw, setMessagesRaw] = useState([] as any);
    const [newMessage, setNewMessage] = useState('');
    const [language, setLanguage] = useState(languages[0].value);
    const [isSending, setIsSending] = useState(false);
    const [showImagination, setShowImagination] = useState(false);
    const [prompt, setPrompt] = useState("");
    const flatListRef = useRef(null);

    const [danPerso, setDanPerso] = useState('');
    const [isMuted, setIsMuted] = useState(true);


    const getLanguage = () => {
        return getLocales()[0].languageCode;
    }

    useEffect(() => {
        const getChatData = async () => {
            try {
                const chatJson = await AsyncStorage.getItem(`${chatName}_data`);
                if (chatJson) {
                    const chatData = JSON.parse(chatJson)
                    setShowImagination(chatData["showImagination"]);
                    setPrompt(chatData["prompt"]);
                    setCharacter(chatData["character"])
                    setDanPerso(chatData["danPerso"])
                }
            } catch (error) {
                console.error('Error storing chat:', error);
            }
        };
        getChatData();
    }, []);

    useEffect(() => {
        const setChatData = async () => {
            try {
                const lastMessage = messages[messages.length - 1]

                return await AsyncStorage.setItem(`${chatName}_data`, JSON.stringify(
                    {
                        showImagination,
                        prompt,
                        character,
                        danPerso,
                        lastMessage,
                    }));
            } catch (error) {
                console.error('Error storing chat:', error);
            }
        };
        setChatData();
    }, [showImagination, prompt, character, danPerso]);

    useEffect(() => {
        if (messages.length > 0 && flatListRef.current) {
            flatListRef.current.scrollToEnd({ animated: true });
            setShowPersos(false);
            setDisableSendButton(false);
        }
    }, [messages]);



    useEffect(() => {
        const language = getLanguage().toUpperCase();
        const l = languages.find(l => l.value === language) || languages[1];
        setLanguage(l.value);
        const fetchChat = async () => {
            try {
                const storedChat = await AsyncStorage.getItem(chatName);
                if (storedChat) {
                    setMessages(JSON.parse(storedChat));
                }
            } catch (error) {
                console.error('Error fetching chat from storage:', error);
            }
        };
        fetchChat();
    }, [chatName]);

    const handleCharacterSelection = async (characterName: string) => {
        if (characterName.trim() === '') return; // ignore empty messages
        //setIsSending(true);
        setCharacter(characterName)
        try {
            setShowPersos(false);
            setShowLoader(true);
            const response = await fetch(server, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    conversation_id: chatName,
                    language: language,
                    mode: danPerso !== '' ? 'DAN' : null,
                    perso: characterName,
                    message: `/${characterName}.`,
                    message_previous: messagesRaw.map((message: any) => `${message.sender}: ${message.text}`).join('\n')
                }),
            });

            const data = await response.json();
            if (!isMuted) {
                Speech.speak(data.message, {
                    rate: 1.1, //, language: 'fr',
                    pitch: 0.75, // optional pitch value between 0 and 2
                });
            }

            const currentTime = new Date().toLocaleTimeString();

            // Drop empty message response
            if (data.message_out_raw == "") {
                setMessages([
                    ...messages,
                    {
                        text: `/${characterName}.`,
                        sender: 'human',
                        time: currentTime
                    }]);
                setMessagesRaw([
                    ...messagesRaw,
                    {
                        text: `/${characterName}.`,
                        sender: 'human',
                        time: currentTime
                    }])
            }
            else {
                setMessages([
                    ...messages,
                    {
                        text: `/${characterName}.`,
                        sender: 'human',
                        time: currentTime
                    },
                    {
                        text: data.message,
                        sender: 'ai',
                        time: currentTime
                    }]);
                setMessagesRaw([
                    ...messagesRaw,
                    {
                        text: `/${characterName}.`,
                        sender: 'human',
                        time: currentTime
                    },
                    {
                        text: data.message,
                        sender: 'ai',
                        time: currentTime
                    }])
            }
            setNewMessage('');
            setDisableSendButton(false);
            setShowLoader(false)
        } catch (error) {
            console.error('Error sending message to ChatGPT:', error);
        } finally {
            //setIsSending(false);
        }
    };


    const handleSend = async () => {
        if (newMessage.trim() === '') return; // ignore empty messages
        setIsSending(true);
        const currentTime = new Date().toLocaleTimeString();
        const _messages = [
            ...messages,
            {
                text: `${newMessage.trim()}`,
                sender: 'human',
                time: currentTime
            }];
        setMessages(_messages);
        setNewMessage('');
        try {
            const response = await fetch(server, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    conversation_id: chatName,
                    language: language,
                    mode: danPerso !== '' ? 'DAN' : null,
                    perso: character,
                    message: newMessage,
                    message_previous: messagesRaw.map((message: any) => `${message.sender}: ${message.text}`).join('\n')
                }),
            });
            const data = await response.json();
            if (character == "imagination") {
                const prompt = data.message.replace('"', '')
                console.log(`prompt: ${prompt}`)
                setShowImagination(true);
                setPrompt(prompt);
            }
            else {
                if (!isMuted) {
                    console.log("I am speaking")
                    Speech.speak(data.message, {
                        rate: 1.1, language: 'fr',
                        pitch: 0.75, // optional pitch value between 0 and 2
                    });
                }

                const currentTimeResp = new Date().toLocaleTimeString();
                setMessages([
                    ..._messages,
                    {
                        text: data.message,
                        sender: 'ai',
                        time: currentTimeResp
                    }]);
                setMessagesRaw([
                    ...messagesRaw,
                    {
                        text: `${data.message_in_raw.trim()}`,
                        sender: 'human',
                        time: currentTimeResp
                    },
                    {
                        text: data.message_out_raw,
                        sender: 'ai',
                        time: currentTime
                    }
                ]);
            }
        } catch (error) {
            console.error('Error sending message to ChatGPT:', error);
        } finally {
            setIsSending(false);
        }
    };

    useEffect(() => {
        const storeChat = async (chat) => {
            try {
                await AsyncStorage.setItem(chatName, JSON.stringify(chat));
            } catch (error) {
                console.error('Error storing chat:', error);
            }
        };
        storeChat(messages);
    }, [messages, chatName]);

    useEffect(() => {
        const storeChat = async (chat) => {
            try {
                await AsyncStorage.setItem(`${chatName}_raw`, JSON.stringify(chat));
            } catch (error) {
                console.error('Error storing chat:', error);
            }
        };
        storeChat(messagesRaw);
    }, [messagesRaw, chatName]);

    // Flatlist height - fix on iOS
    const [height, setHeight] = useState(0);
    const inputHeight = 0;

    // animation
    const [fadeAnim] = useState(new Animated.Value(0));
    useEffect(() => {
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: false
        }).start();
    }, []);

    return (
        <View style={styles.container}>

            {showPersos &&
                <View style={styles.header}>

                    <Animated.View style={{
                        opacity: fadeAnim,
                    }}>
                        <Text style={styles.headerTitle}>Pick your correpondant</Text>

                        <View style={styles.characterImagesContainer}>
                            {persoImages.map((item, index) => (
                                <TouchableOpacity disabled={isSending} key={index} onPress={() => handleCharacterSelection(item.name)} onLongPress={() => { danPerso !== item.name ? setDanPerso(item.name) : setDanPerso('') }}>
                                    <Animated.View style={{
                                        ...styles.characterContainer,
                                        opacity: fadeAnim,
                                    }}>
                                        <View style={styles.characterContainerInner}>
                                            <Image source={item.image} style={{
                                                ...styles.characterImage,
                                                borderWidth: danPerso !== '' ? 0 : 4,
                                            }} />
                                            <Text style={styles.characterName}>{item.name}</Text>
                                        </View>
                                    </Animated.View>
                                </TouchableOpacity>
                            ))}
                        </View>
                    </Animated.View>
                </View>
            }



            <KeyboardAwareFlatList
                onKeyboardWillShow={frames => {
                    let h = frames?.endCoordinates?.height;
                    setHeight(h - inputHeight);
                }}
                onKeyboardWillHide={frames => {
                    setHeight(0);
                }}
                style={{ flexGrow: 0, height: height !== 0 ? height : '100%' }}
                ref={flatListRef}
                data={messages}
                onContentSizeChange={() => flatListRef.current.scrollToEnd({ animated: true })}
                renderItem={({ item }) => {
                    const characterName = item.text.replace('/', '').replace('.', '')
                    const character = persoImages.find(p => p.name === characterName);
                    if (item.text.startsWith('/') && item.text.endsWith('.')) {
                        return (
                            <View style={styles.persoMessageContainer}>
                                <TouchableOpacity onPress={() => { setIsMuted(!isMuted) }}>
                                    <View style={styles.persoMessage}>
                                        <Image style={styles.messageImage} source={character?.image} />
                                        <Text style={styles.persoMessageName}>{characterName}</Text>
                                        {showImagination &&
                                            <Imagination
                                                id={chatName}
                                                countdownDuration={85}
                                                prompt={prompt}
                                            />
                                        }
                                    </View>
                                </TouchableOpacity>
                            </View>
                        );
                    } else {
                        return (
                            <View style={item.sender === 'human' ? styles.userMessageContainer : styles.botMessageContainer}>
                                <View style={item.sender === 'human' ? styles.userMessage : styles.botMessage}>
                                    <View style={styles.messageContent}>
                                        <View style={item.sender === 'human' ? styles.userMessageBubble : styles.botMessageBubble}>
                                            <Text style={styles.messageText}>{item.text}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        );
                    }
                }}
                keyExtractor={(item, index) => index.toString()}
            />
            <View style={[[styles.inputContainer, { opacity: disableSendButton ? 0.4 : 1 }]]}>
                <View style={styles.inputLang}>
                    <RNPickerSelect
                        onValueChange={(value) => setLanguage(value)}
                        items={languages}
                        value={language}
                        placeholder={{}}
                        useNativeAndroidPickerStyle={false}
                    />
                </View>
                <TextInput
                    editable={!disableSendButton}
                    style={[styles.input]}
                    placeholder="Type your message"
                    value={newMessage}
                    multiline={true}
                    onChangeText={setNewMessage}
                />
                <TouchableOpacity disabled={disableSendButton || isSending} style={[styles.sendButton]} onPress={handleSend}>
                    <Text style={styles.sendButtonText}>Send</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
}
const styles = StyleSheet.create({
    container: {
        fleWrap: 'wrap',
        flex: 1,
        backgroundColor: '#fff',
    },
    languagePicker: {
        width: 150,
        marginLeft: 'auto',
        marginRight: 20,
    },
    header: {
        padding: 20,
        paddingTop: 10,
        paddingHorizontal: 5,
        backgroundColor: '#111',
        borderTopWidth: 1,
        borderTopColor: '#222',
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
    },
    headerTitle: {
        paddingTop: 40,
        paddingBottom: 30,
        paddingHorizontal: 100,
        fontSize: 24,
        fontWeight: 'bold',
        color: '#fff',
        textAlign: 'center',
    },
    characterContainer: {
        maxWidth: '100%',
        flexDirection: 'row',
        padding: 7,
    },
    characterContainerInner: {
        alignItems: 'center',
        flexDirection: 'column',
    },
    characterImagesContainer: {
        padding: 0,
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "center",
        flexWrap: 'wrap',
        gap: 5,
    },
    characterImage: {
        margin: 2,
        width: 100,
        height: 100,
        borderRadius: 50,
        borderColor: '#444',
        borderWidth: 1,
    },
    characterName: {
        marginTop: 5,
        fontSize: 11,
        fontWeight: 'bold',
        color: '#fff',
        textAlign: 'center',
        textTransform: 'capitalize',
    },
    messageContainer: {
        paddingVertical: 5,
        paddingHorizontal: 10,
    },
    userMessageContainer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    botMessageContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    message: {
        maxWidth: '70%',
    },
    userMessage: {
        backgroundColor: '#DCF8C6',
        borderRadius: 15,
        borderBottomRightRadius: 0,
    },
    botMessage: {
        backgroundColor: '#FFFFFF',
        borderRadius: 15,
        borderBottomLeftRadius: 0,
    },
    messageContent: {
        paddingHorizontal: 15,
        paddingVertical: 10,
    },
    userMessageBubble: {
        backgroundColor: '#DCF8C6',
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        paddingVertical: 5,
        paddingHorizontal: 10,
    },
    botMessageBubble: {
        backgroundColor: '#fff',
        borderRadius: 15,
        paddingHorizontal: 15,
        paddingVertical: 12,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.2,
        shadowRadius: 1.41,
        elevation: 2,
        width: '90%',
    },
    messageText: {
        color: '#555',
        fontSize: 16,
        lineHeight: 20,
    },
    messageImageContainer: {
        width: 56,
        height: 56,
        borderRadius: 28,
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 8,
    },
    messageImage: {
        width: 90,
        height: 90,
        borderRadius: 45,
        padding: 20,
        borderColor: '#000',
        borderWidth: 2,
    },
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        borderTopWidth: StyleSheet.hairlineWidth,
        borderColor: '#999999',
        padding: 10,
    },
    persoMessageContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },
    persoMessage: {
        backgroundColor: '#FFFFFF',
        paddingTop: 20,
        paddingBottom: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    persoMessageName: {
        marginTop: 5,
        fontSize: 11,
        fontWeight: 'bold',
        color: '#000',
        textAlign: 'center',
        textTransform: 'capitalize',
    },
    input: {
        ...Platform.select({
            ios: {
                height: 60,
            },
            android: {
                // styles for Android
            }
        }),
        flex: 10,
        backgroundColor: '#F4F6F8',
        borderRadius: 10,
        paddingHorizontal: 12,
        paddingVertical: 10,
        fontSize: 16,
        color: '#222222',
    },
    inputLang: {
        flex: 1,
        fontSize: 16,
        color: '#222222',
        paddingVertical: 10,
        paddingHorizontal: 5,
    },
    sendButton: {
        marginLeft: 10,
        backgroundColor: '#000',
        borderRadius: 15,
        paddingVertical: 12,
        paddingHorizontal: 20,
        borderColor: '#ddd',
        borderWidth: 3,
    },
    sendButtonText: {
        color: '#FFFFFF',
        fontSize: 13,
        fontWeight: 'bold',
    },
    persoToggle: {
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    persoToggleShow: {

    },
    persoToggleHide: {
        marginTop: 10,
    },
});
