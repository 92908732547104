import randomWords from 'random-words';
import { v4 as uuidv4 } from 'uuid';
import AsyncStorage from '@react-native-async-storage/async-storage';

const imageNames = [
    require("./assets/images/chats/08af3cde40b0.jpg"),
    require("./assets/images/chats/28beb0deb2a1.jpg"),
    require("./assets/images/chats/3403b8ead181.jpg"),
    require("./assets/images/chats/46ac91fe73d7.jpg"),
    require("./assets/images/chats/4b77df7449c9.jpg"),
    require("./assets/images/chats/56f6cde6801a.jpg"),
    require("./assets/images/chats/6602b74c2d6d.jpg"),
    require("./assets/images/chats/66651e91400a.jpg"),
    require("./assets/images/chats/6a685f44798c.jpg"),
    require("./assets/images/chats/7223a2a7e773.jpg"),
    require("./assets/images/chats/750fd8caccf2.jpg"),
    require("./assets/images/chats/79a847a283e0.jpg"),
    require("./assets/images/chats/7aca5b4a041a.jpg"),
    require("./assets/images/chats/810a9fe2087b.jpg"),
    require("./assets/images/chats/afa9635fe98d.jpg"),
    require("./assets/images/chats/b08c58b84a9e.jpg"),
    require("./assets/images/chats/b9a957f17578.jpg"),
    require("./assets/images/chats/be0d3e3757c9.jpg"),
    require("./assets/images/chats/d02746d46579.jpg"),
    require("./assets/images/chats/de9caba163bb.jpg"),
    require("./assets/images/chats/e00c41a0450b.jpg"),
    require("./assets/images/chats/e8dc8d622f34.jpg"),
    require("./assets/images/chats/ea54dc2492d4.jpg"),
    require("./assets/images/chats/f9be7a66a8e2.jpg"),
]


export const languages = [
    { label: 'FR', value: 'FR' },
    { label: 'EN', value: 'EN' },
    { label: 'TR', value: 'TR' },
    { label: 'DE', value: 'DE' },
    { label: 'IT', value: 'IT' },
];


interface PersoImage {
    name: string;
    image: any;
}

export const persoImages = [
    {
        name: 'cook',
        image: require('./assets/images/perso/cook.jpg')
    },
    {
        name: 'historian',
        image: require('./assets/images/perso/artist.jpg')
    },
    {
        name: 'scientist',
        image: require('./assets/images/perso/scientist.jpg')
    },
    {
        name: 'wise',
        image: require('./assets/images/perso/wise.jpg')
    },
    {
        name: 'storyteller',
        image: require('./assets/images/perso/storyteller.jpg')
    },
    {
        name: 'imagination',
        image: require('./assets/images/perso/imagination.jpg')
    },
    // {
    //     name: 'coach',
    //     image: require('./assets/images/perso/coach.jpg')
    // },
]


/**
 * Returns a random element from an array.
 * @param arr The array to choose from.
 * @returns A randomly chosen element from the array.
 */
function chooseRandomElement<T>(arr: T[]): T {
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
}

export const newChat = () => {
    try {
        const chat: Chat = {
            id: uuidv4(),
            name: randomWords({ exactly: 2, join: '-' }),
            icon: chooseRandomElement(imageNames),
            messages: []
        };
        return chat;
    } catch (error) {
        console.log('Une erreur est survenue :', error);
    }
};

export const fetchChats = async () => {
    try {
        const storedChats = await AsyncStorage.getItem('chats');
        return storedChats
    } catch (error) {
        console.error('Error fetching chats from storage:', error);
    }
};

