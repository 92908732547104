import { useEffect } from 'react';
import ChatList from '../components/ChatList';
import { RootTabScreenProps } from '../types';

export default function Discussions({ navigation }: RootTabScreenProps<'TabOne'>) {
  useEffect(() => {
    console.log("hello")
  });
  return (
    <ChatList />
  );
}