import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, Image, Button } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer';
import axios from 'axios';
const SERVER_URL = 'https://chatgenius.1motion.ch/imagination/'
//const SERVER_URL = 'http://192.168.0.24:3000/';


const Imagination = ({
    id = 'null',
    countdownDuration = 85,
    prompt = '',
}) => {
    const [image, setImage] = useState(null);
    const [jobId, setJobId] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false);
    useEffect(() => {
        const getImaginationData = async () => {
            try {
                const imaginationJson = await AsyncStorage.getItem(`${id}_imagination`);
                if (imaginationJson) {
                    console.log("Imagination was already set");
                    const imaginationData = JSON.parse(imaginationJson);
                    if (imaginationData["image"]) {
                        setImage(imaginationData["image"]);
                    }
                    if (imaginationData["jobId"]) {
                        setJobId(imaginationData["jobId"]);
                    }
                    if (imaginationData["isGenerating"]) {
                        setIsGenerating(imaginationData["isGenerating"]);
                    }
                }
                else {
                    console.log("No imagination yet")
                }
            } catch (error) {
                console.error('Error storing chat:', error);
            }
        };
        getImaginationData();
    }, []);

    useEffect(() => {
        const setImaginationData = async () => {
            try {
                console.log("Setting new imagination status")
                return await AsyncStorage.setItem(`${id}_imagination`, JSON.stringify(
                    {
                        "image": image,
                        "jobId": jobId,
                        "isGenerating": isGenerating,
                    }));
            } catch (error) {
                console.error('Error storing chat:', error);
            }
        };
        setImaginationData();
    }, [image, jobId, isGenerating]);

    const handleGenerateImage = async () => {
        setIsGenerating(true);

        // try {
        // Make a request to the server to start generating the image
        // console.log("Generate Image")
        // console.log(`${SERVER_URL}draw?prompt=${prompt}`)
        // console.log(`${SERVER_URL}draw?prompt=${encodeURIComponent(prompt)}`)
        console.log(`Prompt : ${prompt}`)
        const response = await axios.get(`${SERVER_URL}draw`, {
            params: { prompt }
        });
        // Start the countdown
        const _jobId = response.data.num.replace('(', '').replace(')', '');
        setJobId(_jobId);

        await handleGetImage(_jobId)
        // } catch (error) {
        //     console.error(error);
        // }
    };

    const handleGetImage = async (_jobId: any) => {
        try {
            // Poll the server until the image is ready
            console.log("Get Image")
            console.log(`${SERVER_URL}get?job_id=${_jobId}`)
            const response = await axios.get(`${SERVER_URL}get`, {
                params: { job_id: _jobId }
            });

            console.log(`Done: ${response.data.image_url}`);
            setImage(response.data.image_url);
            setIsGenerating(false);
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <View>
            <Button title="Generate Image" onPress={handleGenerateImage} disabled={isGenerating || jobId !== null} />

            {jobId !== null && image !== null && (
                <Text>{"It's happening wait a bit, jobId"} {jobId}</Text>
                // <CountdownCircleTimer
                //     duration={countdownDuration}
                //     onComplete={handleGetImage}
                // >
                //     {({ remainingTime }) => <Text>{Math.ceil(remainingTime)}</Text>}
                // </CountdownCircleTimer>
            )}

            {image !== null && <Image source={{ uri: image }} style={{ width: 250, height: 250 }} />}
        </View>
    );
};

export default Imagination;